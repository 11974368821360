import Axios from '@/services/Axios';
import UserRequestItems from '@/common/models/UserItems';

const resource = '/user';

export default {
  async getUser(userId) {
    const response = await Axios.get(`${resource}/${userId}`);
    return response.data.data;
  },

  async seePermissions(userId) {
    const response = await Axios.get(`${resource}/permissions/${userId}`);
    return response.data;
  },
  async getUsersAll(
    startDate,
    endDate,
    name,
    employeeNumber,
    company,
    status,
    profile,
    limit,
    offset,
  ) {
    const params = {
      limit,
      offset,
      startDate,
      endDate,
      name,
      employeeNumber,
      company,
      status,
      profile,
    };
    const { data } = await Axios.get(`${resource}`, {
      params,
    });
    return data.map((item) => new UserRequestItems(item));
  },
  async getUsersAllCount(
    startDate,
    endDate,
    name,
    employeeNumber,
    company,
    status,
    profile,
    limit,
    offset,
  ) {
    const params = {
      limit,
      offset,
      startDate,
      endDate,
      name,
      employeeNumber,
      company,
      status,
      profile,
    };
    const { data } = await Axios.get(`${resource}/table/count`, {
      params,
    });
    return data.count;
  },

  async createNewUser(
    id,
    firstName,
    lastName,
    middleName,
    password,
    hireDate,
    email,
    company,
    bossId,
    work,
    profile,
    status,
  ) {
    const params = {
      id,
      firstName,
      lastName,
      middleName,
      password,
      hireDate,
      email,
      company,
      boss_id: bossId,
      works_on_saturdays: work,
      profileId: profile,
      status,
    };
    const response = await Axios.post('/user/newUser', params);
    return response.data;
  },

  async UpdatedUser(
    userId,
    firstName,
    lastName,
    middleName,
    hireDate,
    email,
    company,
    bossId,
    work,
    profile,
    status,
    password,
  ) {
    const params = {
      firstName,
      lastName,
      middleName,
      hireDate,
      email,
      company,
      boss_id: bossId,
      works_on_saturdays: work,
      profileId: profile,
      status,
      password,
    };
    const response = await Axios.put(`${resource}/updatedUser/${userId}`, params);
    return response.data;
  },

  async checkUserWorksOnSaturdays(userId) {
    const response = await Axios.get(`${resource}/${userId}/works_on_saturdays`);
    return response.data;
  },

  async getHolidayDates() {
    const response = await Axios.get('/holidays/dates');
    return response.data;
  },
};
