import Vue from 'vue';
import VueRouter from 'vue-router';
import { TokenUtils } from '@/utils/TokenUtils';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'root',
    component: () => {
      if (!TokenUtils.hasToken()) {
        return import('../views/Login.vue');
      }

      return null;
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
  },
  {
    path: '/app-navigation',
    name: 'app-navigation',
    component: () => import('../components/AppNavigation.vue'),
  },
  {
    path: '/new-request',
    name: 'new-request',
    component: () => import('../views/NewRequest.vue'),
  },
  {
    path: '/:id/edit-request-review',
    name: 'edit-request',
    component: () => import('../views/EdiitRequest.vue'),
  },
  {
    path: '/Users',
    name: 'Users',
    component: () => import('../views/Users.vue'),
  },
  {
    path: '/:id/approver',
    name: 'approver',
    component: () => import('../views/RequestReview.vue'),
  },
  {
    path: '/:id/edit-user',
    name: 'edit-user',
    component: () => import('../views/EditUsers.vue'),
  },
  {
    path: '/new-user',
    name: 'new-user',
    component: () => import('../views/NewUser.vue'),
  },
  {
    path: '/Configuration',
    name: 'configuration',
    component: () => import('../views/Configuration.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
