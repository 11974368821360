import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    attributes: null,
    authUserData: null,
    userName2: null,
  },
  getters: {
    attributes: (state) => state.attributes,
    authUserData: (state) => state.authUserData,
    setNameUser: (state) => state.userName2,
  },
  mutations: {
    SET_ATTRIBUTES(state, data) {
      state.attributes = data;
    },
    SET_AUTH_USER_DATA(state, payload) {
      state.authUserData = payload;
    },
    SET_NAME_USER(state, userName) {
      state.userName2 = userName;
    },
  },
  actions: {
    setAttributes({ commit }, payload) {
      commit('SET_ATTRIBUTES', payload);
    },
    setAuthUserData({ commit }, payload) {
      commit('SET_AUTH_USER_DATA', payload);
    },
    setNameUser({ commit }, payload) {
      commit('SET_NAME_USER', payload);
    },
  },
  modules: {
  },
});
