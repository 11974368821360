function getStatusText(statusCode) {
  switch (statusCode) {
    case '1':
      return 'Activo';
    case '0':
      return 'Inactivo';
    default:
      return 'Desconocida';
  }
}

export default class UserRequestItems {
  constructor(data) {
    this.id = data.id;
    this.fullName = data.fullName;
    this.email = data.email;
    this.status = data.status;
    this.status = getStatusText(data.status);
    this.company = data.company;
    this.hireDate = data.hireDate;
    this.profile = data.permission;
  }
}
